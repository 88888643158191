// dropdown

import styled from 'styled-components';

import Colors from './colors';


const DropdownContent = styled.div`

`;

const DropdownContentTitle = styled.div`
    padding: 0 16px 0 20px;
    height: 36px;
    line-height: 35px;
    font-size: 14px;
    overflow : hidden;
    text-overflow : ellipsis;
    white-space : nowrap;
    font-weight: 700;
    background-color: #EEE;
    border-bottom: 1px solid #CCC;
    border-top: 1px solid #CCC;
    &:first-child {
        border-top: none;
    }
`;

const DropdownContentList = styled.ul`
	position: relative;
    margin: 0;
    padding: 0;
`;
const DropdownContentListItemText = styled.div`
    display: block;
    text-align: left;
    height: 40px;
    padding: 0 16px 0 20px;
    color: ${props => {

        if(props.active) return Colors.Red;
        if(props.error) return Colors.Error;
        return Colors.Dark;
    }};
    background-color: #FFF;      
    font-size: 14px;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: all 250ms cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition-timing-function: cubic-bezier(0.000, 0.000, 0.580, 1.000);
    &:hover {
        background-color: #FAFAFA;
    }

    i {
        margin: auto 14px auto 0;
        font-size: 16px;
    }

    .number {
        margin: 0 0 0 auto;
        color: ${Colors.Dark3};
        font-size: 12px;
    }
`;
const DropdownContentListItem = styled.li`
	padding: 0;
    height: 40px;
    line-height: 40px;
    overflow : hidden;
    text-overflow : ellipsis;
    white-space : nowrap;
    cursor: pointer;
    > a {
        display: block;
        color: ${props => props.active ? Colors.Red : '#777'};
        text-align: left;
        height: 40px;
        padding: 0 16px 0 20px;
        color: ${Colors.Dark};
        background-color: #FFF;      
        font-size: 14px;
        display: flex;
        align-items: center;
        text-decoration: none;
        transition: all 250ms cubic-bezier(0.000, 0.000, 0.580, 1.000);
        transition-timing-function: cubic-bezier(0.000, 0.000, 0.580, 1.000);
        &:hover {
            background-color: #FAFAFA;
            color: ${props => props.active ? Colors.Red : Colors.Dark};
        }

        i {
            margin: auto 14px auto 0;
            font-size: 16px;
        }

        .number {
            margin: 0 0 0 auto;
            color: ${Colors.Dark3};
            font-size: 12px;
        }
    }
`;

const DropdownContainer = styled.div`
	min-width: 240px;
	box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4);
	border-radius: 3px;	
	background-color: #FFF;
`;

const DropdownStyle = {

	DropdownContainer,
    DropdownContent,
    
    DropdownContentTitle,

	DropdownContentList, 
    DropdownContentListItem,
    DropdownContentListItemText,

}

export default DropdownStyle;