/*=====================================
    Topbar Menu

    Author: Alan
    CreateTime: 2021 / 06 / 22
=====================================*/

import React from 'react';

import styled from 'styled-components';

import actionCreators from '../../actions/creators';
import useRedux from '../../actions/hooks/useRedux';
import { createSelector } from 'reselect';

import { GlobalStyle } from '../../stylecomponents';
import CupoyRouter from "../../components/commons/router/CupoyRouter";
import CupoySrefView from "../../components/commons/router/CupoySrefView";


/*--------------------------
    Styles
--------------------------*/
const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: ${GlobalStyle.TopbarZindex - 10};
    visibility: ${props => props.active ? 'visible' : 'hidden'};
`;
const Overloay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: ${GlobalStyle.TopbarZindex - 9};
`;
const Content = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 300px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    z-index: ${GlobalStyle.TopbarZindex - 8};
    background-color: #000;
    transform: ${props => props.active ? 'translateX(0)' : 'translateX(100%)'};
    transition: all 300ms cubic-bezier(0.000, 0.000, 0.580, 1.000); /* ease-out */
    transition-timing-function: cubic-bezier(0.000, 0.000, 0.580, 1.000); /* ease-out */
`;

const NavBar = styled.ul`
    margin: 70px 0 0;
    padding: 0;
    flex: 1;
`;
const NavItem = styled.li`
    display: flex;
    align-items: center;
    margin: 0;
    padding: 20px 40px;
    color: #FFF;
    list-style: none;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
`;

/*--------------------------
    Main Cpmonent
--------------------------*/
const TopbarView = (props) => {

    const selector = createSelector(
        (state) => ({
            showTopbarMenu: state.web.showTopbarMenu,
        }),
        (reduxResult) => reduxResult
    );

    const [reduxState, {
        webActions
    }] = useRedux(selector, {
        webActions: actionCreators.webActionCreators
    });

    const {
        showTopbarMenu
    } = reduxState;

    const toggleTopbarMenu = () => {

        if (showTopbarMenu) {
            webActions.closeTopbarMenu();
        } else {
            webActions.showTopbarMenu();
        }
    }

    return (
        <Container active={showTopbarMenu}>
            <Overloay onClick={toggleTopbarMenu} />
            <Content active={showTopbarMenu}>
                <NavBar>
                    <CupoySrefView 
                        stylecomponent={NavItem}
                        statename={CupoyRouter.STATES.HOME}
                        onClick={toggleTopbarMenu}
                    >
                        Home
                    </CupoySrefView>
                    <CupoySrefView 
                        stylecomponent={NavItem}
                        statename={CupoyRouter.STATES.MISSION_STATEMENT}
                        onClick={toggleTopbarMenu}
                    >
                        Mission Statement
                    </CupoySrefView>
                    <CupoySrefView 
                        stylecomponent={NavItem}
                        statename={CupoyRouter.STATES.PET_DECOLORIZATION}
                        onClick={toggleTopbarMenu}
                    >
                        PET Decolorization
                    </CupoySrefView>
                    <CupoySrefView 
                        stylecomponent={NavItem}
                        statename={CupoyRouter.STATES.PET_DEPOLYMERIZATION}
                        onClick={toggleTopbarMenu}
                    >
                        PET Depolymerization
                    </CupoySrefView>
                    <CupoySrefView 
                        stylecomponent={NavItem}
                        statename={CupoyRouter.STATES.MICROFLUIDIC_SYSTEM_FOR_MOF_SYNTHESIS}
                        onClick={toggleTopbarMenu}
                    >
                        Microfluidic system for MOF synthesis
                    </CupoySrefView>
                    <CupoySrefView 
                        stylecomponent={NavItem}
                        statename={CupoyRouter.STATES.ABOUT_US}
                        onClick={toggleTopbarMenu}
                    >
                        About Us
                    </CupoySrefView>
                </NavBar>
            </Content>
        </Container>
    );
}


export default TopbarView;