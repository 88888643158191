import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducerCreators from '../reducers/';
import { logger, crashReporter } from '../actionMiddlewares';
import thunk from "redux-thunk";
// import { createHashHistory as createHistory } from 'history'
import { createHashHistory as createHistory } from 'history'
import { routerMiddleware } from 'react-router-redux'
import { connectRouter } from 'connected-react-router'

const history: History = createHistory({
    hashType: 'slash',
})
// const history = createHistory();

const middleWare = applyMiddleware(
    thunk,
    logger,
    crashReporter,
    routerMiddleware(history)
);

const initWebState = {
    history,
    // scrollPosition: {},
    // renderedIndex: {},
    isShowLogoutModal: false,
    isShowDeleteConfirmModal: false,
    deleteConfirmCallback: null,
    showTopbarMenu: false,

    // 確認視窗
    confirmDialog: {
        isOpen: false,
        title: undefined,
        description: undefined,
        labelIcon: undefined,
        isPromise: false,
        confirmCallback: undefined,
        confirmPromise: undefined,
        cancelCallback: undefined,
        confirmName: undefined,
        cancelName: undefined,
        needRemark: false,
        remarkLabel: undefined,
    },
    alertDialog: {
        isOpen: false,
        title: undefined,
        description: undefined,
        confirmName: undefined,
        callback: undefined,
    }
};

const actionReducers = combineReducers({
    web: reducerCreators.createWebReducers({}),
    router: connectRouter(history)
});

let actionStore = createStore(
    actionReducers,
    {
        web: initWebState,
    },
    composeWithDevTools(middleWare)
);

export default actionStore;