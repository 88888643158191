// import GLOBAL from '../../global';
import actionTypes from '../actionTypes';
// import Global from '../../global';
// import { push, go, replace } from '../router';
import { push, /* go, */ replace } from 'react-router-redux';

import CupoyRouter from "../../components/commons/router/CupoyRouter";

// ------------------------------
// 轉址（不會記錄上下頁）
// ------------------------------
function replaceRoutePath(path) {
	return function(dispatch, getState) {
		return dispatch(replace(path));
	};
}

// ------------------------------
// Cupoy State 轉址
// ------------------------------
function cupoyStateGo(statename, params, querys, locationState) {

	return function(dispatch, getState) {

		if(!CupoyRouter.hasState(statename)) {
			throw new Error('CupoyState has not state: ' + statename);
		}

		const url = CupoyRouter.getPathname(statename, params);
		const search = CupoyRouter.getQueryUrl(querys);

		let location =  {
			pathname: url,
			search: search,
			state: locationState,
		};

        // const history = getState().web.history;
        // history.push(url);

		return dispatch(push(location));
	};
}

// ------------------------------
// Cupoy State 轉址（不會記錄上下頁）
// ------------------------------
function cupoyStateReplace(statename, params, querys, locationState) {

	return function(dispatch, getState) {

		if(!CupoyRouter.hasState(statename)) {
			throw new Error('CupoyState has not state: ' + statename);
		}

		const url = CupoyRouter.getPathname(statename, params);
		const search = CupoyRouter.getQueryUrl(querys);

		let location =  {
			pathname: url,
			search: search,
			state: locationState,
		};

		return dispatch(replace(location));
	};
}

// ------------------------------
// Cupoy State 轉至預設頁
// ------------------------------
function cupoyStateGoOtherwise() {

	return function(dispatch, getState) {

		const otherwise = CupoyRouter.getOtherwise();

		if(otherwise) {

			setTimeout(() => {
				// 不知道為什麼首次進入就執行這行會被瀏覽器踢回原網址，所以先用 setTimeout 處理
				return dispatch(replace(otherwise));	
			})
		}
	};
}

function storeScrollPosition(key, scrollTop) {

	const payload = {
		key,
		scrollTop
	};
	
	return { type: actionTypes.storeScrollPosition, payload };
}

function storeRenderedIndex(key, event) {
	const payload = {
		key,
		event
	};
	return { type: actionTypes.storeRenderedIndex, payload };
}

function showLogoutModal(payload) {
	return { type: actionTypes.showLogoutModal, payload };
}

function closeLogoutModal(payload) {
	return { type: actionTypes.closeLogoutModal, payload };
}

function showDeleteConfirmModal(payload) {
	return { type: actionTypes.showDeleteConfirmModal, payload };
}

function closeDeleteConfirmModal(payload) {
	return { type: actionTypes.closeDeleteConfirmModal, payload };
}

function openConfirmDialog(data) {
	
    const payload = {
        title: data.title,
        description: data.description,
        labelIcon: data.labelIcon,
        isPromise: data.isPromise,
        confirmCallback: data.confirmCallback,
        confirmPromise: data.confirmPromise,
        cancelCallback: data.cancelCallback,
        confirmName: data.confirmName,
		cancelName: data.cancelName,
		needRemark: data.needRemark,
		remarkLabel: data.remarkLabel,
		remark: data.remark,
		otherButtons: data.otherButtons,
	};
	
    return { type: actionTypes.openConfirmDialog, payload };
}

function closeConfirmDialog(payload) {
    return { type: actionTypes.closeConfirmDialog, payload };
}

function openNoteEditorDialog(data) {

	const payload = {
		title: data.title,
		content: data.content,
		html: data.html,
		onSave: data.onSave,
		onClose: data.onClose,
		probeURLCallback: data.probeURLCallback,
		updateNoteAttachImageFile: data.updateNoteAttachImageFile,
		updateNoteAttachTextFile: data.updateNoteAttachTextFile,
		updateNoteAttachImageURL: data.updateNoteAttachImageURL,
		disableTitle: data.disableTitle,
	};

	return { type: actionTypes.openNoteEditorDialog, payload }
}

function closeNoteEditorDialog () {

	return { type: actionTypes.closeNoteEditorDialog }
}

function showAcceptRefundDialog(payload) {
	return { type: actionTypes.showAcceptRefundDialog, payload };
}

function closeAcceptRefundDialog(payload) {
	return { type: actionTypes.closeAcceptRefundDialog, payload };
}


function openAlertDialog(payload = {}) {
	return {
		type: actionTypes.openAlertDialog,
		payload,
	};
}

function closeAlertDialog() {
	return {
		type: actionTypes.closeAlertDialog,
	};
}

function showTopbarMenu(payload) {
	return {
		type: actionTypes.showTopbarMenu,
		payload
	}
}
function closeTopbarMenu(payload) {
	return {
		type: actionTypes.closeTopbarMenu,
		payload
	}
}



const webActions = {

	replaceRoutePath,
	cupoyStateGo,
	cupoyStateReplace,
	cupoyStateGoOtherwise,
	storeScrollPosition,
	storeRenderedIndex,
	showLogoutModal,
	closeLogoutModal,
	showDeleteConfirmModal,
	closeDeleteConfirmModal,
	openConfirmDialog,
	closeConfirmDialog,
	openNoteEditorDialog,
	closeNoteEditorDialog,
	showAcceptRefundDialog,
	closeAcceptRefundDialog,
	openAlertDialog,
	closeAlertDialog,
	showTopbarMenu,
	closeTopbarMenu,
}

export default webActions;
