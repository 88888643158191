import React, {
    useEffect
} from 'react';
import {
    Provider
} from 'react-redux'
import RootView from './components/RootView';
import actionStore from './actions/store/configureStore';

const App = props => {

    useEffect(() => {

        // 關閉瀏覽器自行管理scroll位置歷史紀錄的功能
        if (
            typeof window !== 'undefined' &&
            'scrollRestoration' in window.history
        ) {
            window.history.scrollRestoration = 'manual';
        }

    }, [])

    return ( 
        <Provider store = {actionStore}>
            <RootView />
        </Provider>
    )
}

export default App;