/*=====================================
    RootSwitchView

    Author: Alan
    createtime: 2019 / 07 / 01
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { Component, lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import styled from 'styled-components';
import { GlobalStyle } from '../../../stylecomponents';
import CupoyRouter from "./CupoyRouter";
import CupoyRouterLazyLoadingView from './CupoyRouterLazyLoadingView';

/*--------------------------
    Style Component
--------------------------*/

/*--------------------------
    Style Component
--------------------------*/
const SwitchContent = styled.div`
    position: relative;
    width: 100%;
`;
const SwitchModal = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: ${GlobalStyle.TopbarZindex + 1};
`;

/*--------------------------
    Variables
--------------------------*/
var openModalLastScrollTop = 0;
var isLockSwitchContent = false;


/*--------------------------
    Lazy Import Components
--------------------------*/
const HomeViewComponent = lazy(() => import('../../home/HomeView'));
const MissionStatementViewComponent = lazy(() => import('../../home/MissionStatementView'));
const PetDecolorizationViewComponent = lazy(() => import('../../solution/PetDecolorizationView')); 
const PetDepolymerizationViewComponent = lazy(() => import('../../solution/PetDepolymerizationView')); 
const MicrofluidicSystemForMOFSynthesisViewComponent = lazy(() => import('../../solution/MicrofluidicSystemForMOFSynthesisView')); 
const AboutUsViewComponent = lazy(() => import('../../home/AboutUsView'));

/*--------------------------
    Main Component
--------------------------*/
class CupoyRouterSwitchView extends Component {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {
        super(props);

        this.state = {
            // 
        };

        this._getLazyComponent = this._getLazyComponent.bind(this);
        this.getShowLoadingLazyComponent = this._getLazyComponent.bind(this, true);
        this.getLazyComponent = this._getLazyComponent.bind(this, false);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount () {}

    _getLazyComponent(isShowLoading, Component, otherProps = {}) {
        return props => (
            <Suspense fallback={isShowLoading ? <CupoyRouterLazyLoadingView /> : null}>
                <Component {...props} {...props.match.params} {...otherProps} />
            </Suspense>
        );
    }

    getSwitchContentStyle(isModal) {

        var contentStyle = {};

        if(isModal) {
            contentStyle.position = 'fixed';
            contentStyle.transform = 'translateZ(0)';

            if(!isLockSwitchContent) {
                openModalLastScrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop;
                isLockSwitchContent = true;

                requestAnimationFrame(function() {
                    window.scrollTo(0, 0);
                });
            }

            contentStyle.top = -openModalLastScrollTop + 'px';

        } else if(isLockSwitchContent) {
            isLockSwitchContent = false;

            requestAnimationFrame(function() {
                window.scrollTo(0, openModalLastScrollTop);    
            });
        }

        return contentStyle;
    }

    // ------------------------------
    // render
    // ------------------------------    
    render() {

        const { location } = this.props;
        const isModal = !!(location.state && location.state.modal && this.previousLocation && this.previousLocation !== location); // not initial render
        const contentStyle = this.getSwitchContentStyle(isModal);

        // console.log(location);

        return (
            <div>
                <SwitchContent style={contentStyle}>
                    <Switch location={location}>
                        <Route path={CupoyRouter.PATHS.MISSION_STATEMENT} render={this.getShowLoadingLazyComponent(MissionStatementViewComponent)} exact/> 
                        <Route path={CupoyRouter.PATHS.PET_DECOLORIZATION} render={this.getShowLoadingLazyComponent(PetDecolorizationViewComponent)} exact/> 
                        <Route path={CupoyRouter.PATHS.PET_DEPOLYMERIZATION} render={this.getShowLoadingLazyComponent(PetDepolymerizationViewComponent)} exact/> 
                        <Route path={CupoyRouter.PATHS.MICROFLUIDIC_SYSTEM_FOR_MOF_SYNTHESIS} render={this.getShowLoadingLazyComponent(MicrofluidicSystemForMOFSynthesisViewComponent)} exact/> 
                        <Route path={CupoyRouter.PATHS.ABOUT_US} render={this.getShowLoadingLazyComponent(AboutUsViewComponent)} exact/> 
                        <Route path={CupoyRouter.PATHS.HOME} render={this.getShowLoadingLazyComponent(HomeViewComponent)} exact/> 
                    </Switch>
                </SwitchContent>

                {isModal ? 
                    <SwitchModal />
                    :
                    null
                }   
            </div>
        );
    }
}

export default CupoyRouterSwitchView;
