

const actionTypes = {
	// webActions Start -------------------------
	storeScrollPosition: "storeScrollPosition",
	storeRenderedIndex: "storeRenderedIndex",
	showLogoutModal: "showLogoutModal",
	closeLogoutModal: "closeLogoutModal",
	showDeleteConfirmModal: "showDeleteConfirmModal",
	closeDeleteConfirmModal: "closeDeleteConfirmModal",
	openConfirmDialog: "openConfirmDialog",
	closeConfirmDialog: "closeConfirmDialog",
	openNoteEditorDialog: "openNoteEditorDialog",
	closeNoteEditorDialog: "closeNoteEditorDialog",
	showAcceptRefundDialog: 'showAcceptRefundDialog',
	closeAcceptRefundDialog: 'closeAcceptRefundDialog',
	openAlertDialog: 'openAlertDialog',
	closeAlertDialog: 'closeAlertDialog',
	openAddOrEditContactRecordDialog: 'openAddOrEditContactRecordDialog',
	closeAddOrEditContactRecordDialog: 'closeAddOrEditContactRecordDialog',
	showTopbarMenu: 'showTopbarMenu',
	closeTopbarMenu: 'closeTopbarMenu',
	// webActions End ---------------------------



}

export default actionTypes;