import actionTypes from '../actionTypes';

const reducers = {
	[actionTypes.storeScrollPosition]: function(state, action) {
		return storeScrollPosition(state, action);
	},
	[actionTypes.storeRenderedIndex]: function(state, action) {
		return storeRenderedIndex(state, action);
	},
	[actionTypes.showLogoutModal]: function(state, action) {
		return Object.assign({}, state, {
			isShowLogoutModal: true
		});
	},
	[actionTypes.closeLogoutModal]: function(state, action) {
		return Object.assign({}, state, {
			isShowLogoutModal: false
		});
	},
	[actionTypes.showDeleteConfirmModal]: function(state, action) {
		return Object.assign({}, state, {
			isShowDeleteConfirmModal: true,
			deleteConfirmCallback: action.payload.deleteConfirmCallback
		});
	},
	[actionTypes.closeDeleteConfirmModal]: function(state, action) {
		return Object.assign({}, state, {
			isShowDeleteConfirmModal: false,
			deleteConfirmCallback: null
		});
	},
	[actionTypes.openConfirmDialog]: function(state, action) {
		return openConfirmDialog(state, action);
	},
	[actionTypes.closeConfirmDialog]: function(state, action) {
		return closeConfirmDialog(state, action);
	},
	[actionTypes.openNoteEditorDialog]: function(state, action) {
		return openNoteEditorDialog(state, action);
	},
	[actionTypes.closeNoteEditorDialog]: function(state, action) {
		return closeNoteEditorDialog(state, action);
	},
	[actionTypes.showAcceptRefundDialog]: function(state, action) {
		return showAcceptRefundDialog(state, action);
	},
	[actionTypes.closeAcceptRefundDialog]: function(state, action) {
		return closeAcceptRefundDialog(state, action);
	},
	[actionTypes.openAlertDialog]: function(state, action) {
		return openAlertDialog(state, action);
	},
	[actionTypes.closeAlertDialog]: function(state, action) {
		return closeAlertDialog(state, action);
	},

	[actionTypes.showTopbarMenu]: function(state, action) {
		return showTopbarMenu(state, action);
	},
	[actionTypes.closeTopbarMenu]: function(state, action) {
		return closeTopbarMenu(state, action);
	},
};

export default function createReducers(initialState) {
	return function reducer(state = initialState, action) {
		if (reducers.hasOwnProperty(action.type)) {
			return reducers[action.type](state, action);
		} else {
			return state;
		}
	};
}



function storeScrollPosition(state, action) {
	if (state.scrollPosition[action.payload.key] === action.payload.scrollTop) {
		return state;
	}

	const newScrollPosition = Object.assign({}, state.scrollPosition, {
		[action.payload.key]: action.payload.scrollTop
	});

	return Object.assign({}, state, {
		scrollPosition: newScrollPosition
	});
}

function storeRenderedIndex(state, action) {
	if (state.renderedIndex[action.payload.key] === action.payload.event) {
		return state;
	}

	const newRenderedIndex = Object.assign({}, state.renderedIndex, {
		[action.payload.key]: action.payload.event
	});

	return Object.assign({}, state, {
		renderedIndex: newRenderedIndex
	});
}

function openConfirmDialog(state, action) {

	var confirmDialog = state.confirmDialog;

	if(confirmDialog.isOpen) {
      	return state;
	}

	const payload = action.payload;

	var new_confirmDialog = Object.assign({}, confirmDialog, {
		isOpen: true,
		title: payload.title || undefined,
		description: payload.description || undefined,
		labelIcon: payload.labelIcon || undefined,
		isPromise: payload.isPromise,
		confirmCallback: payload.confirmCallback || undefined,
		confirmPromise: payload.confirmPromise || undefined,
		cancelCallback: payload.cancelCallback || undefined,
		confirmName: payload.confirmName || undefined,
		cancelName: payload.cancelName || undefined,
		needRemark: payload.needRemark || false,
		remarkLabel: payload.remarkLabel || undefined,
		remark: payload.remark || undefined,
		otherButtons: Array.isArray(payload.otherButtons) ? payload.otherButtons : []
	});

    return Object.assign({}, state, {
        confirmDialog: new_confirmDialog,
    });
}

function closeConfirmDialog(state, action) {

	var confirmDialog = state.confirmDialog;

	if(!confirmDialog.isOpen) {
      	return state;
	}

    var new_confirmDialog = Object.assign({}, confirmDialog, {
		isOpen: false,
		title: undefined,
		description: undefined,
		labelIcon: undefined,
		isPromise: false,
		confirmCallback: undefined,
		confirmPromise: undefined,
		cancelCallback: undefined,
		confirmName: undefined,
		cancelName: undefined,
		needRemark: false,
		remarkLabel: undefined,
	});

    return Object.assign({}, state, {
        confirmDialog: new_confirmDialog,
    });
}

function openNoteEditorDialog(state, action) {

	var noteEditor = state.noteEditor;

	const payload = action.payload;

	var new_noteEditor = Object.assign({}, noteEditor, {
		isOpen: true,
		title: payload.title || undefined,
		content: payload.content || undefined,
		html: payload.html || undefined,
		onSave: payload.onSave || undefined,
		onClose: payload.onClose || undefined,
		probeURLCallback: payload.probeURLCallback || undefined,
		updateNoteAttachImageFile: payload.updateNoteAttachImageFile || undefined,
		updateNoteAttachTextFile: payload.updateNoteAttachTextFile || undefined,
		updateNoteAttachImageURL: payload.updateNoteAttachImageURL || undefined,
		disableTitle: payload.disableTitle || false,
	});

    return Object.assign({}, state, {
        noteEditor: new_noteEditor,
    });
}

function closeNoteEditorDialog(state, action) {

	var noteEditor = state.noteEditor;

    var new_noteEditor = Object.assign({}, noteEditor, {
		isOpen: false,
		title: undefined,
		content: undefined,
		html: undefined,
		onSave: undefined,
		onClose: undefined,
		probeURLCallback: undefined,
		updateNoteAttachImageFile: undefined,
		updateNoteAttachTextFile: undefined,
		updateNoteAttachImageURL: undefined,
		disableTitle: false,
	});

    return Object.assign({}, state, {
        noteEditor: new_noteEditor,
    });
}


function showAcceptRefundDialog(state, action) {

	const { acceptRefundDialog } = state;
	const { isOpen } = acceptRefundDialog;
	const { orderid } = action.payload

	if(isOpen) return state;

	const new_acceptRefundDialog = {
		isOpen: true,
		orderid
	};

	const new_state = Object.assign({}, state, {
		acceptRefundDialog: new_acceptRefundDialog,
	});

	return new_state;
}

function closeAcceptRefundDialog(state) {

	const { acceptRefundDialog } = state;
	const { isOpen } = acceptRefundDialog;

	if(!isOpen) return state;

	const new_acceptRefundDialog = {
		isOpen: false,
		orderid: ''
	};

	const new_state = Object.assign({}, state, {
		acceptRefundDialog: new_acceptRefundDialog,
	});

	return new_state;
}
function openAlertDialog(state, { payload }) {

	const {
		title,
		description,
		confirmName,
		callback,
	} = payload;
	
	const { alertDialog } = state;

	if(alertDialog.isOpen) return state;

	const newState = Object.assign({}, state, {
		alertDialog: {
			isOpen: true,
			title,
			description,
			confirmName,
			callback,
		},
	});

	return newState;
}

function closeAlertDialog(state) {

	const { alertDialog } = state;

	if(!alertDialog.isOpen) return state;

	const newState = Object.assign({}, state, {
		alertDialog: {
			isOpen: false,
			title: undefined,
			description: undefined,
			confirmName: undefined,
			callback: undefined,
		},
	});

	return newState;
}

function showTopbarMenu(state) {

	if(state.showTopbarMenu)
		return state;

	return Object.assign({}, state, {
		showTopbarMenu: true
	})
}
function closeTopbarMenu(state) {

	if(!state.showTopbarMenu)
		return state;

	return Object.assign({}, state, {
		showTopbarMenu: false
	})
}
