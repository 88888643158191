/*=====================================
    Root

    Author: Alan
    CreateTime: 2021 / 06 / 22
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { 
    // useEffect
} from "react";

// import actionCreators from '../actions/creators';
import useRedux from '../actions/hooks/useRedux';
import { createSelector } from 'reselect';

import { Route } from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router';
import CupoyRouterView from "../components/commons/router/CupoyRouterView";
import CupoyRouterSwitchView from "../components/commons/router/CupoyRouterSwitchView";

import { GlobalStyle } from '../stylecomponents';
import Topbar from './topbar';
import TopbarMenu from './topbar/TopbarMenu';

/*--------------------------
    Main Components
--------------------------*/
const RootView = (props) => {
    
    const selector = createSelector(
        (state) => ({
            history: state.web.history,
            isConfirmDialogOpen: state.web.confirmDialog.isOpen,
            isAlertDialogOpen: state.web.alertDialog.isOpen,
            showTopbarMenu: state.web.showTopbarMenu,
        }),
        (reduxResult) => reduxResult
    );

    const [reduxState] = useRedux(selector);

    const {
        history,
        // isLogin,
        // isShowLogoutModal,
        // isConfirmDialogOpen,
        // isAlertDialogOpen
    } = reduxState; 


    return (
        <CupoyRouterView>
            <ConnectedRouter history={history}>
                <div>

                    <Topbar />
                    <TopbarMenu />

                    <GlobalStyle.Container>
                        <Route component={CupoyRouterSwitchView} />
                    </GlobalStyle.Container>

                    {/* {isLogin ? renderContent() : <LoginView/>}

                    {isShowLogoutModal && <LogoutDialog/>}
                    {isConfirmDialogOpen && <ConfirmDialog/>}
                    {isAlertDialogOpen && <AlertDialog />} */}
                </div>
            </ConnectedRouter>
        </CupoyRouterView>
    );
}

/*--------------------------
    Export
--------------------------*/
export default RootView;
