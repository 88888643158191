import styled from 'styled-components';
import Colors from './colors';
import Dialog from "rc-dialog";
import 'rc-dialog/assets/index.css';

/*--------------------------
    Style Variables
--------------------------*/

/*--------------------------
    Style Keyframes
--------------------------*/

/*--------------------------
    Style Component
--------------------------*/
const Content = styled(Dialog).attrs(props => {


	let maskStyle = null;
	let bodyStyle = null;
	let style = null;
	let wrapClassName = undefined;


	if(props.maskStyle) {
		maskStyle = props.maskStyle;
	}

	if(props.maskBackgroundColor) {
		props.maskStyle = { backgroundColor: props.maskBackgroundColor };
	}

	if(props.bodyStyle) {
		bodyStyle = props.bodyStyle;
	}

	bodyStyle = {
		padding: props.padding ? props.padding : '20px',
		height: props.innerFullHeight ? '100%' : undefined,
	}

	if(props.style) {
		style = props.style;
	}

	style = {
		width: props.width ? props.width : '400px',
		minWidth: props.minWidth ? props.minWidth : undefined,
		maxWidth: props.maxWidth ? props.maxWidth : '400px',
		height: props.height ? props.height : undefined,
		minHeight: props.minHeight ? props.minHeight : undefined,
		maxHeight: props.maxHeight ? props.maxHeight : undefined,
		margin: props.margin ? props.margin : 'auto',
		transition: props.transition ? props.transition : undefined,
	}

	if(props._wrapClassName) {
		wrapClassName = 'dialog-flex ' + props._wrapClassName;
	}

	wrapClassName = 'dialog-flex';


	return {
		maskStyle,
		bodyStyle,
		style,
		wrapClassName
	}

	// maskStyle: props => {

	// 	if(props.maskStyle) {
	// 		return props.maskStyle;
	// 	}

	// 	if(props.maskBackgroundColor) {
	// 		return {
	// 			backgroundColor: props.maskBackgroundColor,
	// 		}
	// 	} else {
	// 		return null
	// 	}
	// },
	// bodyStyle: props => {

	// 	if(props.bodyStyle) {
	// 		return props.bodyStyle;
	// 	}

	// 	return {
	// 		padding: props.padding ? props.padding : '20px',
	// 		height: props.innerFullHeight ? '100%' : undefined,
	// 	}
	// },
	// style: props => {

	// 	if(props.style) {
	// 		return props.style;
	// 	}

	// 	return {
	// 		width: props.width ? props.width : '400px',
	// 		minWidth: props.minWidth ? props.minWidth : undefined,
	// 		maxWidth: props.maxWidth ? props.maxWidth : '400px',
	// 		height: props.height ? props.height : undefined,
	// 		minHeight: props.minHeight ? props.minHeight : undefined,
	// 		maxHeight: props.maxHeight ? props.maxHeight : undefined,
	// 		margin: props.margin ? props.margin : 'auto',
	// 		transition: props.transition ? props.transition : undefined,
	// 	}
	// },
	// wrapClassName: props => {

	// 	if(props._wrapClassName) {
	// 		return 'dialog-flex ' + props._wrapClassName;
	// 	}
		
	// 	return 'dialog-flex';
	// }
})`
	// Dialog 沒開 content style 的 prop，只能用此方式覆寫
    .rc-dialog-content {
    	background-color: ${props => props.backgroundColor ? props.backgroundColor : '#ffffff'};
        border-radius: ${props => props.borderRadius ? props.borderRadius : '3px'};
        /* overflow: hidden; */

        ${(props) => {
			
        	var result = ``;

			if(props.border) {
				result += `
					border: ${props.border};
				`;
			}

			if(props.boxShadow) {
				result += `
					box-shadow: ${props.boxShadow};
				`;
			}

			if(props.innerFullHeight) {
				result += `
					height: 100%;
				`;
			}

			return result;
		}}
    }
`;
const CloseButton = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 20px;
    color: ${Colors.Dark4};
    z-index: 1;
    cursor: pointer;

    &:hover {
        color: ${Colors.Dark};
    }
`;

const DialogStyle = {
	/*--------------------------
		Variables
	--------------------------*/

	/*--------------------------
		Component
	--------------------------*/
	Content,
	CloseButton,
}

export default DialogStyle;