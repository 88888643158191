/*=====================================
    全域共用樣式

    Author: Gray
    createtime: 2017 / 12 / 21
=====================================*/

/*--------------------------
    Import 
--------------------------*/
import styled, { keyframes } from 'styled-components';
import Colors from './colors';

/*--------------------------
    Style Variables
--------------------------*/
const ContainerWidth = '100%';
const ContainerMinWidth = 768;
const TopbarHeight = 60;
const TabbarHeight = 60;
const ViewSpace = 12; // 預設的留白空間
const BreadCrumbsHeight = 50; // 麵包屑的高度
const TopbarZindex = 950;
const SidebarWidth = 220;
const NavSideBarrZindex = 10;

/*--------------------------
    Style Keyframes
--------------------------*/
const rotate360 = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`;

/*--------------------------
    Style Component
--------------------------*/
const Topbar = styled.div`
    position: fixed;
    top: 0;
    left: 0;
	right: 0;
    height: ${TopbarHeight}px;
    background-color: transparent;
    z-index: ${TopbarZindex};
	transform: translateZ(0);
	
`;
const Container = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
`;
const Content = styled.div`
	padding: ${props => props.topbar ? `${TopbarHeight}px 0 0` : 0};
`;

const Spin = styled.div`
	position: relative;
	vertical-align: sub;
	display: ${props => props.display ? props.display : 'inline-block'};
	width: ${props => props.size ? props.size : '20px'};
	height: ${props => props.size ? props.size : '20px'};
	margin: ${props => props.margin ? props.margin : '0 auto'};
	border-width: ${props => props.borderWidth ? props.borderWidth : '2px'};
	border-style: solid;
	border-top-color: ${props => props.bgColor ? props.bgColor : 'transparent'};
	border-left-color: ${props => props.bgColor ? props.bgColor : 'transparent'};
	border-right-color: ${props => props.color ? props.color : Colors.Main};
	border-bottom-color: ${props => props.color ? props.color : Colors.Main};
	border-radius: 100%;
	animation: ${rotate360} 0.6s linear infinite;
`;
const LoadingContainer = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	height: 112px;
	align-items: center;
	justify-content: center;
`;
const LoadingSpinCenter = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
`;

const BreadCrumbs = styled.div`
	position: relative;
	width: 100%;
	height: ${BreadCrumbsHeight}px;
	background-color: transparent;
	/* box-shadow: inset 0 -1px 0 0 #DDDDDD; */
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
    width: calc(100% - 160px);
    @media screen and (min-width: 1024px) and (max-width: 1400px) {
        width: calc(100% - 80px);
    }
    @media screen and (min-width: 769px) and (max-width: 1023px) {
        width: calc(100% - 40px);
    }
    @media screen and (max-width: 768px) {
		width: calc(100% - 20px);
	}
`;
const BreadCrumbsContent = styled.ul`
	width: 100%; // 左右留12px
    line-height: ${BreadCrumbsHeight}px;
	margin: 0;
	padding: 0;
	display: inline-flex;	
`;
const BreadCrumbsItemText = styled.div`
	display: inline;
	color: ${Colors.Title};
`;
const BreadCrumbsItem = styled.li`
	cursor: ${props => props.active ? 'auto' : 'pointer'};
	font-size: 13px;
	list-style: none;
	a {
		text-decoration: none;
		color: ${props => props.active ? '#FFF' : '#DDD'};
	}
	${BreadCrumbsItemText} {
		color: ${props => props.active ? '#FFF' : '#DDD'};
	}
	&:hover {
		a {
			color: #FFF;
		}
	}
`;

const BreadCrumbArrow = styled.div`
	display: inline-flex;
	align-items: center;
	margin: 0 8px;
	color: #FFF;
	font-size: 18px;
`;


const StateContainer = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	align-items: center;
	justify-content: center;
	min-height: 100px;
`;
const StateContent = styled.div`
	text-align: center;
	max-width: 480px;
`;
const StateTtile = styled.h3`
	font-size: 18px;
	margin: 0;
	color: ${Colors.Title};
`;
const StateDescription = styled.p`
	font-size: 16px;
	margin: 20px 0 0;
	color: ${Colors.Paragraph};
`;
const StateAction = styled.div`


`;


const SubTopbar = styled.div`
	position: fixed;
	top: ${TopbarHeight}px;
	left: ${props => props.sidebar ? SidebarWidth : 0}px;;
	right: 0;
	height: 50px;
	background-color: #FFFFFF;
	box-shadow: inset 0 -1px 0 0 #DDDDDD;
	z-index: ${TopbarZindex-1};
`;
const SubTopbarContent = styled.div`
	position: relative;
	width: ${ContainerWidth}; // 左右留20px
	height: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
`;
const SubTopbarLeftPannel = styled.div`
	display: inline-flex;
	flex: 1;
	height: 100%;
`;
const SubTopbarRightPannel = styled.div`
	display: inline-flex;
	height: 100%;
	align-items: center;
`;
const SubTopbarItemLink = styled.div`
	font-size: 14px;
	color: #AFAFAF;
`;
const SubTopbarItemLabel = styled.div`
	color: #333435;
	font-size: 14px;
	padding: 0 0 0 40px;
	&:first-child {
		padding: 0;
	}
`;
const SubTopbarItem = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 0 10px;
	cursor: pointer;
	${SubTopbarItemLink} {
		position: relative;
		color: ${props => props.active ? '#D75455' : '#AFAFAF'};

		&:before {
			content: ${props => props.active ? '""' : 'none'};
			position: absolute;
			left: 0;
			right: 0;
			bottom: -4px;
			height: 1px;
			background-color: #D75455;
		}
	}

	&:hover {
		${SubTopbarItemLink} {
			color: #D75455;
		}
	}
`;



const GlobalStyle = {
	/*--------------------------
		Variables
	--------------------------*/
	ContainerWidth,
	ContainerMinWidth,
	TopbarHeight,
	TabbarHeight,
	ViewSpace,
	BreadCrumbsHeight,
	TopbarZindex,
	SidebarWidth,
	NavSideBarrZindex,

	/*--------------------------
		Component
	--------------------------*/
	Topbar,
	Container,
	Content,
	Spin,
	LoadingContainer,
	LoadingSpinCenter,

	/*--------------------------
		BreadCrumbs
	--------------------------*/
	BreadCrumbs,
	BreadCrumbsContent,
	BreadCrumbsItem,
	BreadCrumbsItemText,
	BreadCrumbArrow,

	/*--------------------------
		Fix Sub Header
	--------------------------*/
	SubTopbar,
	SubTopbarContent,
	SubTopbarLeftPannel,
	SubTopbarRightPannel,

	SubTopbarItem,
	SubTopbarItemLabel,
	SubTopbarItemLink,

	/*--------------------------
		State Container
	--------------------------*/
	StateContainer,
	StateContent,
	StateTtile,
	StateDescription,
	StateAction,

}

export default GlobalStyle;