/*=====================================
	全域顏色樣式

	Author: Gray
	CreateTime: 2018 / 07 / 24
=====================================*/

/*--------------------------
    Style Variables
--------------------------*/
const Main = '#746868';
const Red = '#D75455';
const Yellow = '#EEE552';
const Orange = '#F19840';
const Blue = '#2E7AC3';
const Green = '#008675';
const Purple = '#905bdf';
const NavTextHover = '#746868'

const Dark = '#565151';
const Dark2 = '#6C6666';
const Dark3 = '#8A807F';
const Dark4 = '#A79E9D';
const Dark5 = '#C6BDBC';
const Dark6 = '#E2DFDE';
const Dark7 = '#F0EFEE';
const Dark8 = '#FDF3F3';

const Title = '#333435';
const Paragraph = '#565151';
const LightText = '#A79E9D';
const Text = '#565151';

const Success = '#008675';
const Error = '#D75455';
const Editable = '#fef2cc';

/*--------------------------
    Style Method
--------------------------*/

// ------------------------------
// [private] 轉換公式
// ------------------------------
function hex(x) {
	
	return ("0" + parseInt(x, 10).toString(16)).slice(-2);
}

// ------------------------------
// 1 ~ 100 變亮, -1 ~ -100 變暗
// ------------------------------
function LightenDarkenColor(col, amt) {
  
    var usePound = false;
  
    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }
 
    var num = parseInt(col,16);
 
    var r = (num >> 16) + amt;
 
    if (r > 255) r = 255;
    else if  (r < 0) r = 0;
 
    var b = ((num >> 8) & 0x00FF) + amt;
 
    if (b > 255) b = 255;
    else if  (b < 0) b = 0;
 
    var g = (num & 0x0000FF) + amt;
 
    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    const _hex = (g | (b << 8) | (r << 16)).toString(16);
    if(_hex.length !== 6) {
    	return (usePound?"#":"") + (hex(r) + hex(g) + hex(b));
    } else {
    	return (usePound?"#":"") + _hex;
    }
}

// ------------------------------
// RGBA
// ------------------------------
function RGBA(hex, opacity) {

	// http://stackoverflow.com/a/5624139
  	var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  	hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    	return r + r + g + g + b + b;
  	});

  	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  	result = result ? 'rgba(' + parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) + ',' + opacity + ')' : null;
  	return result;
}

const Colors = {
	/*--------------------------
		Style Variables
	--------------------------*/
	Main,
	Red,
	Yellow,
	Orange,
	Blue,
	Green,
	Purple,
	Dark,
	Dark2,
	Dark3,
	Dark4,
	Dark5,
	Dark6,
	Dark7,
	Dark8,
	Title,
	Paragraph,
	LightText,
	Text,
	Success,
	Error,
	Editable,
	NavTextHover,

	/*--------------------------
		Style Method
	--------------------------*/
	LightenDarkenColor,
	RGBA,
}

export default Colors;