/*=====================================
    Topbar

    Author: Alan
    CreateTime: 2021 / 06 / 22
=====================================*/

import React from 'react';

import styled from 'styled-components';
import { FaLeaf } from "react-icons/fa";

import actionCreators from '../../actions/creators';
import useRedux from '../../actions/hooks/useRedux';
import { createSelector } from 'reselect';

import { GlobalStyle } from '../../stylecomponents';
import CupoyRouter from "../../components/commons/router/CupoyRouter";
import CupoySrefView from "../../components/commons/router/CupoySrefView";


/*--------------------------
    Styles
--------------------------*/
const TopbarContent = styled.div`
    position: relative;
    /* width: ${GlobalStyle.ContainerMinWidth}px; // 左右留12px */
    width: calc(100% - 160px);
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    @media screen and (min-width: 1024px) and (max-width: 1400px) {
        width: calc(100% - 80px);
    }
    @media screen and (min-width: 769px) and (max-width: 1023px) {
        width: calc(100% - 40px);
    }
    @media screen and (max-width: 768px) {
        width: calc(100% - 20px);
    }
`;
const Logo = styled.h1`
    display: inline-flex;
    margin: 0;
`;
const LogoLink = styled.a`
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #FFF;
    font-size: 24px;
`;
// const LogoImg = styled.img`
//     margin: auto;
// `;

const NavItem = styled.li`
    margin: 0;
    padding: 0 20px;
    color: #FFF;
    list-style: none;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.95;
    &:hover {
        opacity: 1;
    }

    @media screen and (min-width: 1025px) and (max-width: 1480px) {
        padding: 0 10px;
        font-size: 16px;
    }
    /* @media screen and (min-width: 769px) and (max-width: 1023px) {
        padding: 0 10px;
        font-size: 16px;
    } */
    @media screen and (max-width: 1024px) {
        display: none
    }
`;
const NavBar = styled.ul`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    flex: 1;
    opacity: ${props => props.active ? 1 : 0.3};
    ${NavItem} {
        pointer-events: ${props => props.active ? 'all' : 'none'};
    }
`;

const Hambaber = styled.div`
    position: relative;
    display: flex;
    width: 50px;
    height: 60px;
    margin: 0 0 0 10px;
    cursor: pointer;
    z-index: ${GlobalStyle.TopbarZindex + 10};
    > div {
        position: absolute;
        left: 50%;
        width: 30px;
        height: 2px;
        background-color: #FFF;
        border-radius: 1px;
        transform: translateX(-50%);
        transition: all 300ms cubic-bezier(0.000, 0.000, 0.580, 1.000); /* ease-out */
        transition-timing-function: cubic-bezier(0.000, 0.000, 0.580, 1.000); /* ease-out */
        &:nth-child(1) {
            top: 50%;
            transform: ${props => props.active ? 'translateX(-50%) translateY(-50%) rotate(45deg)' : 'translateX(-50%) translateY(-50%) translateY(-8px)'};
        }
        &:nth-child(2) {
            top: 50%;
            transform: ${props => props.active ? 'translateX(-50%) translateY(-50%) scale(0)' : 'translateX(-50%) translateY(-50%)'};
        }
        &:nth-child(3) {
            top: 50%;
            transform: ${props => props.active ? 'translateX(-50%) translateY(-50%) rotate(-45deg)' : 'translateX(-50%) translateY(-50%) translateY(8px)'};
        }
    }
`;

/*--------------------------
    Main Cpmonent
--------------------------*/
const TopbarView = (props) => {

    const selector = createSelector(
        (state) => ({
            showTopbarMenu: state.web.showTopbarMenu,
        }),
        (reduxResult) => reduxResult
    );

    const [reduxState, {
        webActions
    }] = useRedux(selector, {
        webActions: actionCreators.webActionCreators
    });

    const {
        showTopbarMenu
    } = reduxState;

    const toggleTopbarMenu = () => {

        if (showTopbarMenu) {
            webActions.closeTopbarMenu();
        } else {
            webActions.showTopbarMenu();
        }
    }

    return (
        <GlobalStyle.Topbar>
            <TopbarContent>
                <Logo>
                    <CupoySrefView
                        statename={CupoyRouter.STATES.HOME}
                        className="logo"
                        stylecomponent={LogoLink}
                    >
                        <FaLeaf />
                        {/* <LogoImg
                            src={'images/logo-white.svg'}
                            width="86"
                            height="30"
                        /> */}
                    </CupoySrefView>
                </Logo>

                <NavBar active={!showTopbarMenu}>
                    <CupoySrefView 
                        stylecomponent={NavItem}
                        statename={CupoyRouter.STATES.MISSION_STATEMENT}
                    >
                        Mission Statement
                    </CupoySrefView>
                    <CupoySrefView 
                        stylecomponent={NavItem}
                        statename={CupoyRouter.STATES.PET_DECOLORIZATION}
                    >
                        PET Decolorization
                    </CupoySrefView>
                    <CupoySrefView 
                        stylecomponent={NavItem}
                        statename={CupoyRouter.STATES.PET_DEPOLYMERIZATION}
                    >
                        PET Depolymerization
                    </CupoySrefView>
                    <CupoySrefView 
                        stylecomponent={NavItem}
                        statename={CupoyRouter.STATES.MICROFLUIDIC_SYSTEM_FOR_MOF_SYNTHESIS}
                    >
                        Microfluidic system for MOF synthesis
                    </CupoySrefView>
                    <CupoySrefView 
                        stylecomponent={NavItem}
                        statename={CupoyRouter.STATES.ABOUT_US}
                    >
                        About Us
                    </CupoySrefView>

                </NavBar>

                <Hambaber active={showTopbarMenu} onClick={toggleTopbarMenu}>
                    <div />
                    <div />
                    <div />
                </Hambaber>

            </TopbarContent>
        </GlobalStyle.Topbar>
    );
}


export default TopbarView;